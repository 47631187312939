<template>
  <v-container fluid grid-list-lg class="self-align-center h-100 d-flex pa-0">
    <v-container fluid grid-list-lg class="align-self-center pa-0">
      <v-layout row wrap class="pa-0">
        <add-ticket-stepper />
      </v-layout>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: 'AddEvent',
  components: {
    AddTicketStepper: () =>
      import(
        /* webpackChunkName: "AddTicketStepper" */ '@/components/AddingTicket/AddTicketStepper'
      )
  }
}
</script>
<style type="scss" scoped>
::v-deep .v-content__wrap {
  display: flex;
}
</style>
